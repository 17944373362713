let colors = {
    'hausastrid': '#A82D03',
    'suedstrand': '#3F7BA1',
    'hausnina': '#4688BE'
};

export const state = () => ({
    fewoDatas: {
        'hausastrid': [
            {
                "logotitle": "Ferienwohnung Haus Astrid",
                "logo": "2020-01-29-logo-hausastrid.svg",
                "color": colors.hausastrid,
                "headline": "Ferienwohnungen für 1-6 Personen im Herzen von Warnemünde",
                "description": "Ferienwohnungen für 1-6 Personen im Herzen von Warnemünde",
                "headerimgMobile": "hausastrid/buehne_HA_768x768.jpg",
                "headerimg": "hausastrid/buehne_HA.jpg",
                "qm": "???",
                "price": "60,00 €",
                "footer": '<p>Besuchen Sie auch unsere Ferienwohnungen am <a href="https://www.suedstrand-wohnung.de/" target="_blank"><span class="icon-house"></span>Südstrand</a> in Fehmarn oder im <a href="https://www.hausnina.de/" target="_blank"><span class="icon-house"></span>Haus Nina</a> in Rostock/Warnemünde</p>',
                "fewos": [
                    {
                        "gallery": [
                            {
                                "caption": "Wohnzimmer",
                                "img": 'hausastrid/gallery/seemoeve/seemoewe_couch+stuehle@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/seemoeve/seemoewe_couch+stuehle@2x.jpg?resize&size=768').src,
                                "src": require('~/assets/img/hausastrid/gallery/seemoeve/seemoewe_couch+stuehle@2x.jpg?resize').src
                            },
                            {
                                "caption": "Schlafzimmer",
                                "img": 'hausastrid/gallery/seemoeve/seemoewe_schlafzimmer@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/seemoeve/seemoewe_schlafzimmer@2x.jpg?resize&size=768').src,
                                "src": require('~/assets/img/hausastrid/gallery/seemoeve/seemoewe_schlafzimmer@2x.jpg?resize').src
                            },
                            {
                                "caption": "Küche",
                                "img": 'hausastrid/gallery/seemoeve/seemoewe_kueche@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/seemoeve/seemoewe_kueche@2x.jpg?resize&size=768').src,
                                "src": require('~/assets/img/hausastrid/gallery/seemoeve/seemoewe_kueche@2x.jpg?resize').src
                            },
                            {
                                "caption": "Bad",
                                "img": 'hausastrid/gallery/seemoeve/seemoewe_bad@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/seemoeve/seemoewe_bad@2x.jpg?resize&size=768').src,
                                "src": require('~/assets/img/hausastrid/gallery/seemoeve/seemoewe_bad@2x.jpg?resize').src
                            }
                        ],
                        "title": "Seemöwe",
                        "description": "<p>Die schöne Hochparterre-Wohnung in Südlage eignet sich für 1-2 Personen. Sie bietet ein großes Wohnzimmer mit integriertem Schlafbereich, eine komplett eingerichtete Küche und ein Bad mit Dusche. Ein Babybett und ein Hochstuhl können bei Bedarf hinzugestellt werden.</p>" +
                            "<p><strong>Ausstattung:</strong><br/>\n" +
                            "Kabelfernsehen, Radio, Geschirrspüler, Dusche/WC, Tiere erlaubt, WLan vorhanden, Bettwäsche auf Anfrage.</p>",
                        "price": "60,00 €",
                        "qm": "30 m²",
                        "person": "max. 2",
                        "priceNight": "60,00 - 65,00 €",
                        "dog": "10,00 €",
                        "cleaning": "50,00 €"
                    },
                    {
                        "gallery": [
                            {
                                "caption": "Wohnzimmer",
                                "img": 'hausastrid/gallery/sturmflut/strurmflut_wohnzimmer1@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/sturmflut/strurmflut_wohnzimmer1@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausastrid/gallery/sturmflut/strurmflut_wohnzimmer1@2x.jpg?resize').src
                            },
                            {
                                "caption": "Wohnzimmer",
                                "img": 'hausastrid/gallery/sturmflut/strurmflut_wohnzimmer2@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/sturmflut/strurmflut_wohnzimmer2@2x.jpg?resize&size=768').src,
                                "src": require('~/assets/img/hausastrid/gallery/sturmflut/strurmflut_wohnzimmer2@2x.jpg?resize').src
                            },
                            {
                                "caption": "Schlafzimmer",
                                "img": 'hausastrid/gallery/sturmflut/strurmflut_schlafzimmer@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/sturmflut/strurmflut_schlafzimmer@2x.jpg?resize&size=768').src,
                                "src": require('~/assets/img/hausastrid/gallery/sturmflut/strurmflut_schlafzimmer@2x.jpg?resize').src
                            },
                            {
                                "caption": "Küche",
                                "img": 'hausastrid/gallery/sturmflut/sturmflut_kueche@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/sturmflut/sturmflut_kueche@2x.jpg?resize&size=768').src,
                                "src": require('~/assets/img/hausastrid/gallery/sturmflut/sturmflut_kueche@2x.jpg?resize').src
                            },
                            {
                                "caption": "Bad",
                                "img": 'hausastrid/gallery/sturmflut/strurmflut_bad@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/sturmflut/strurmflut_bad@2x.jpg?resize&size=768').src,
                                "src": require('~/assets/img/hausastrid/gallery/sturmflut/strurmflut_bad@2x.jpg?resize').src
                            }
                        ],
                        "title": "Sturmflut",
                        "description": "<p>Die 1 ½ Zimmer-Wohnung liegt im Hochparterre und eignet sich für 1-3 Personen. Sie besteht aus einem Wohnschlafraum mit mehreren Sitzgelegenheiten und einer abgetrennten Veranda. Weiterhin gibt es eine komplett ausgestattete Küche und ein Badezimmer mit Dusche.</p>" +
                            "<p>Ein Babybett und ein Hochstuhl können bei Bedarf hinzugestellt werden.</p>" +
                            "<p><strong>Ausstattung:</strong><br/>\n" +
                            "Kabelfernsehen, Radio, Geschirrspüler, Dusche/WC, Tiere erlaubt, WLan vorhanden, Bettwäsche auf Anfrage.</p>",
                        "price": "75,00 €",
                        "qm": "47 m²",
                        "person": "max. 3",
                        "priceNight": "75,00 - 80,00 €",
                        "dog": "10,00 €",
                        "cleaning": "50,00 €"
                    },
                    {
                        "gallery": [
                            {
                                "caption": "Wohnzimmer",
                                "img": 'hausastrid/gallery/piratenschiff/Piratenschiff_wohnzimmer@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/piratenschiff/Piratenschiff_wohnzimmer@2x.jpg?resize&size=100').src,
                                "src": require('~/assets/img/hausastrid/gallery/piratenschiff/Piratenschiff_wohnzimmer@2x.jpg?resize').src
                            },
                            {
                                "caption": "Schlafzimmer",
                                "img": 'hausastrid/gallery/piratenschiff/Piratenschiff_schlafzimmer@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/piratenschiff/Piratenschiff_schlafzimmer@2x.jpg?resize&size=100').src,
                                "src": require('~/assets/img/hausastrid/gallery/piratenschiff/Piratenschiff_schlafzimmer@2x.jpg?resize').src
                            },
                            {
                                "caption": "Schlafzimmer",
                                "img": 'hausastrid/gallery/piratenschiff/Piratenschiff_schlafzimmer-2@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/piratenschiff/Piratenschiff_schlafzimmer-2@2x.jpg?resize&size=100').src,
                                "src": require('~/assets/img/hausastrid/gallery/piratenschiff/Piratenschiff_schlafzimmer-2@2x.jpg?resize').src
                            },
                            {
                                "caption": "Kinderzimmer",
                                "img": 'hausastrid/gallery/piratenschiff/Piratenschiff_Kinderzimmer@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/piratenschiff/Piratenschiff_Kinderzimmer@2x.jpg?resize&size=100').src,
                                "src": require('~/assets/img/hausastrid/gallery/piratenschiff/Piratenschiff_Kinderzimmer@2x.jpg?resize').src
                            },
                            {
                                "caption": "Küche",
                                "img": 'hausastrid/gallery/piratenschiff/Piratenschiff_kueche@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/piratenschiff/Piratenschiff_kueche@2x.jpg?resize&size=100').src,
                                "src": require('~/assets/img/hausastrid/gallery/piratenschiff/Piratenschiff_kueche@2x.jpg?resize').src
                            },
                            {
                                "caption": "Bad",
                                "img": 'hausastrid/gallery/piratenschiff/Piratenschiff_bad@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/piratenschiff/Piratenschiff_bad@2x.jpg?resize&size=100').src,
                                "src": require('~/assets/img/hausastrid/gallery/piratenschiff/Piratenschiff_bad@2x.jpg?resize').src
                            },
                            {
                                "caption": "Balkon",
                                "img": 'hausastrid/gallery/piratenschiff/Piratenschiff_balkon@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/piratenschiff/Piratenschiff_balkon@2x.jpg?resize&size=100').src,
                                "src": require('~/assets/img/hausastrid/gallery/piratenschiff/Piratenschiff_balkon@2x.jpg?resize').src
                            }
                        ],
                        "title": "Piratenschiff",
                        "description": "<p>Die Ferienwohnung erstreckt sich mit drei wunderschönen großen Wohnräumen über die gesamte erste Etage und eignet sich für 1-6 Personen. Vom Wohnzimmer mit zwei Schlafgelegenheiten gelangen Sie auf den großen Balkon, der genug Platz und Sitzgelegenheiten für ein gemütliches Essen im Freien bietet.</p>" +
                            "<p>Die beiden separaten Schlafzimmer sind unterschiedlich ausgestattet. Eines verfügt über ein Doppelbett, das andere eignet sich mit zwei Einzelbetten auch gut als Kinderschlafzimmer.</p>" +
                            "<p>Weiterhin gib es eine komplett ausgestattete Küche und ein Bad mit Dusche. Ein Babybett und ein Hochstuhl können bei Bedarf hinzugestellt werden.</p>" +
                            "<p><strong>Ausstattung:</strong><br/>\n" +
                            "Kabelfernsehen, Radio, Geschirrspüler, Dusche/WC und zusätzlicher Toilette, Tiere erlaubt, WLan vorhanden, Bettwäsche auf Anfrage.</p>",
                        "price": "90,00 €",
                        "qm": "72 m²",
                        "person": "max. 6",
                        "priceNight": "90,00 - 105,00 €",
                        "dog": "10,00 €",
                        "cleaning": "65,00 €"
                    },
                    {
                        "gallery": [
                            {
                                "caption": "Wohnzimmer",
                                "img": 'hausastrid/gallery/leuchtturm/Leuchtturm_wohnzimmer@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/leuchtturm/Leuchtturm_wohnzimmer@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausastrid/gallery/leuchtturm/Leuchtturm_wohnzimmer@2x.jpg?resize').src
                            },
                            {
                                "caption": "Schlafzimmer",
                                "img": 'hausastrid/gallery/leuchtturm/Leuchtturm_schlafzimmer1@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/leuchtturm/Leuchtturm_schlafzimmer1@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausastrid/gallery/leuchtturm/Leuchtturm_schlafzimmer1@2x.jpg?resize').src
                            },
                            {
                                "caption": "Schlafzimmer",
                                "img": 'hausastrid/gallery/leuchtturm/Leuchtturm_schlafzimmer2@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/leuchtturm/Leuchtturm_schlafzimmer2@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausastrid/gallery/leuchtturm/Leuchtturm_schlafzimmer2@2x.jpg?resize').src
                            },
                            {
                                "caption": "Küche",
                                "img": 'hausastrid/gallery/leuchtturm/Leuchtturm_kueche@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/leuchtturm/Leuchtturm_kueche@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausastrid/gallery/leuchtturm/Leuchtturm_kueche@2x.jpg?resize').src
                            },
                            {
                                "caption": "Bad",
                                "img": 'hausastrid/gallery/leuchtturm/Leuchtturm_bad@2x.jpg',
                                "thumb": require('~/assets/img/hausastrid/gallery/leuchtturm/Leuchtturm_bad@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausastrid/gallery/leuchtturm/Leuchtturm_bad@2x.jpg?resize').src
                            }
                        ],
                        "title": "Leuchtturm",
                        "description": "<p>Die Ferienwohnung erstreckt sich über die gesamte 2. Etage und eignet sich für 1-6 Personen. Über den großzügigen Eingangsbereich können alle Zimmer separat betreten werden. Das Wohnzimmer verfügt über zwei Schlafgelegenheiten und zahlreiche Sitzmöglichkeiten.</p>" +
                            "<p>Weiterhin gibt es ein Schlafzimmer mit Doppelbett und zwei weitere Schlafzimmer mit Einzelbetten.</p>" +
                            "<p>Komplettiert wird die Ferienwohnung von einem Badezimmer mit Dusche und einer vollausgestatteten Küche. Ein Babybett und ein Hochstuhl können bei Bedarf hinzugestellt werden.</p>" +
                            "<p><strong>Ausstattung:</strong><br/>\n" +
                            "Kabelfernsehen, Radio, Geschirrspüler, Dusche/WC, Tiere erlaubt, WLan vorhanden, Bettwäsche auf Anfrage.</p>",
                        "price": "95,00 €",
                        "qm": "76 m²",
                        "person": "max. 6",
                        "priceNight": "95,00 - 115,00 €",
                        "dog": "10,00 €",
                        "cleaning": "65,00 €"
                    }
                ],
                "seasons": [],
                "priceinfo": "<p>Die genannten Preise beziehen sich auf eine Übernachtung pro Ferienwohnung. Im Mietpreis enthalten sind Strom und Wasser. Bettwäsche kann nach Absprache gestellt werden. Bitte berücksichtigen Sie, dass unsere Preise je nach Anzahl der Gäste und Saisonzeit variieren können. Ein verbindliches Angebot für den von Ihnen gewünschten Zeitraum erhalten Sie daher nur auf Anfrage.<\/p>",
                "locationinfo": "Haus Astrid befindet sich ruhig und zentral im Herzen von Warnemünde. Der Strand ist etwa 250 m entfernt, das Zentrum und den Alten Strom erreichen Sie in 5 Gehminuten. Auch der Leuchtturm und die Mole befinden sich in unmittelbarer Nähe.",
                "address": "<p>Schulstr. 5<br/>18119 Rostock (Warnemünde)<br/>Deutschland</p>",
                "mapslink": "https://www.google.de/maps/dir//Schulstra%C3%9Fe+5,+18119+Rostock/@54.1784704,12.0818134,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47acf8a0738b92c7:0x164f94b2215dd437!2m2!1d12.0840074!2d54.1784704",
                "maps": "hausastrid/map-haus-astrid.jpg",
                "contactAddress": "<p>Heinz-Georg Schäfer<br/>\n" +
                    "                        Edelhofdamm 6<br/>\n" +
                    "                        13465 Berlin<br/>\n<br/>\nTel: + 49 (0) 30 401 63 84<br/>\n" +
                    "                        Fax: + 49 (0) 30 401 02 113<br/>\n<br/>\n</p>",
                "contactEMail": "kontakt@hausastrid.de",
                "contactHeadline": "Buchen Sie Ihre Ferienwohnung im Haus Astrid",
                "contactInfos": [
                    {
                        "icon": "icon-private.svg",
                        "title": "Privater Anbieter"
                    },
                    {
                        "icon": "icon-raum-zu-freien-entfaltung.svg",
                        "title": "Ideal für Familien"
                    },
                    {
                        "icon": "icon-keine-versteckten-Kosten.svg",
                        "title": "Keine versteckten Kosten"
                    },
                    {
                        "icon": "icon-seebadarchitektur.svg",
                        "title": "Traditionelle Seebadarchitektur"
                    }
                ]
            }
        ]
    }
})
